.App {
  margin: 0;
  padding: 0;
  text-align: center;
  background-color: #333;
  height: 100%;
  width: auto;
  color: #fff
}

.contents {
  width: min(90%, 1200px);
  margin-left: auto;
  margin-right: auto;
}

.title {
  font-size: 40px;
  padding-top: 60px;
  font-weight: bold;
}

.sub-title {
  font-size: 20px;
  padding-top: 20px;
  padding-bottom: 60px;
}

.data-kind {
  padding-bottom: 20px;
}

.graphs {
  width: 100%;
  text-align: start;
}
